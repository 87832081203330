





























import { Autoplay, Pagination, Swiper } from 'swiper'
import { Component, Prop, Ref, Vue } from 'vue-property-decorator'
import { TranslateResult } from 'vue-i18n'

import { ContainerWidth } from '../../../../../atoms'

import {
  HeroModuleSliderVersionContent, HeroModuleVersion,
  ResolvedHeroModuleSlideContent
} from '../../../Hero.contracts'

import {
  SWIPER_AUTOPLAY_DELAY,
  SWIPER_OPTIONS
} from './Slider.config'

/**
 * "Slider" version of the `HeroModuleUi`.
 *
 * @see HeroModule
 * @see HeroModuleVersion
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<Slider>({
  name: 'Slider',
  components: {
    FillableCircle: () => import(
      /* webpackChunkName: "frame" */
      '../../partials/FillableCircle/FillableCircle.vue'
    ),
    SlideContent: () => import(
      /* webpackChunkName: "frame" */
      '../../partials/SlideContent/SlideContent.vue'
    )
  },
  mounted (): void {
    this.initSwiper()
  }
})
export class Slider extends Vue {
  /**
   * @see HeroModuleSliderVersionContent.slides
   */
  @Prop({ type: Array, required: true })
  public readonly slides!: ResolvedHeroModuleSlideContent[]

  /**
   * @see HeroModuleSliderVersionContent.containerWidth
   */
  @Prop({ type: String, required: false, default: ContainerWidth.Normal })
  public readonly containerWidth!: HeroModuleSliderVersionContent['containerWidth']

  /**
   * @see HeroModuleContent.hasBottomSpacing
   */
  @Prop({ type: Boolean, required: false, default: false })
  public readonly hasBottomSpacing!: Required<HeroModuleSliderVersionContent>['hasBottomSpacing']

  @Prop({ type: String, required: true })
  public readonly htmlId!: string

  @Prop({ type: String, required: false })
  public readonly nonce?: string

  public readonly version = HeroModuleVersion

  /**
   * Determines whether the slider is currently in the active autoplay mode (is playing).
   *
   * @see swiper
   */
  public isPlaying: boolean = false

  /**
   * Swiper instance.
   */
  private swiper: Swiper | null = null

  /**
   * Reference to the `.swiper-container` element.
   */
  @Ref('swiperContainer')
  private readonly swiperContainerRef!: HTMLDivElement

  /**
   * Handles the `@click` event on the "play/pause" button.
   */
  public onPlayPauseBtnClick (): void {
    this.isPlaying ? this.pauseSwiper() : this.playSwiper()
  }

  /**
   * Label for the "play/pause" button.
   */
  public get playPauseBtnLabel (): TranslateResult {
    return this.$t(`modules.components.modules.Hero.versions.Slider.play-pause-btn.${
      this.isPlaying ? 'pause' : 'play'
    }`)
  }

  /**
   * Determines whether the component has all the data it needs for a successful render.
   */
  public get shouldRender (): boolean {
    return this.hasSlides
  }

  /**
   * Determines whether the component has been provided with the correct `slides` prop.
   */
  private get hasSlides (): boolean {
    return typeof this.slides !== 'undefined' &&
      Array.isArray(this.slides) &&
      this.slides.length > 0
  }

  public get style (): string {
    return `#${this.htmlId} {
      --speed: ${SWIPER_AUTOPLAY_DELAY}ms;
    }`
  }

  /**
   * Initialises the Swiper.
   */
  private initSwiper (): void {
    if (typeof window === 'undefined' && this.swiperContainerRef) {
      return
    }

    Swiper.use([Autoplay, Pagination])
    this.swiper = new Swiper(this.swiperContainerRef, {
      ...SWIPER_OPTIONS,
      on: {
        afterInit: this.onSwiperAfterInit,
        autoplayStart: this.onSwiperAutoplayStart,
        autoplayStop: this.onSwiperAutoplayStop
      }
    })
  }

  /**
   * Handles the `afterInit` event emitted by the Swiper.
   */
  private onSwiperAfterInit (): void {
    setTimeout(() => {
      if (this.swiper === null) {
        throw new Error('Swiper.on.afterInit(): [this.swiper] is [null]!')
      }

      this.swiper.updateAutoHeight(300)
    }, 300)
  }

  /**
   * Handles the `autoplayStart` event emitted by the Swiper.
   */
  private onSwiperAutoplayStart (): void {
    this.isPlaying = true
  }

  /**
   * Handles the `autoplayStop` event emitted by the Swiper.
   */
  private onSwiperAutoplayStop (): void {
    this.isPlaying = false
  }

  /**
   * Pauses the Swiper's autoplay animation.
   */
  private pauseSwiper (): void {
    setTimeout(() => {
      if (this.swiper === null) {
        throw new Error('Slider.pauseSwiper(): [this.swiper] is [null]!')
      }

      this.swiper.autoplay.stop()
    })
  }

  /**
   * Resumes the Swiper's autoplay animation.
   */
  private playSwiper (): void {
    setTimeout(() => {
      if (this.swiper === null) {
        throw new Error('Slider.playSwiper(): [this.swiper] is [null]!')
      }

      this.swiper.autoplay.start()
    })
  }
}

export default Slider
