











import { BootstrapImageProps } from '@movecloser/ui-core'
import { Component, Prop, Vue } from 'vue-property-decorator'

import { AnimatedImage } from '../AnimatedImage'
import { UiGrid } from '../UiGrid'

/**
 * Component that's capable of rendering the Grid of AnimatedImages.
 *
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'AnimatedGrid',
  components: { AnimatedImage, UiGrid }
})
export class AnimatedGrid extends Vue {
  /**
   * @see AnimatedImage.animationDuration
   */
  @Prop({ type: Number, required: false })
  public animationDuration?: AnimatedImage['animationDuration']

  /**
   * @see UiGrid.columnGap
   */
  @Prop({ type: String, required: false })
  public columnGap?: UiGrid['columnGap']

  /**
   * @see UiGrid.columns
   */
  @Prop({ type: [Number, String], required: false })
  public columns?: UiGrid['columns']

  /**
   * Image to render in grid
   */
  @Prop({ type: Array, required: true })
  public items!: Array<BootstrapImageProps>

  /**
   * @see UiGrid.rowGap
   */
  @Prop({ type: String, required: false })
  public rowGap?: UiGrid['rowGap']

  /**
   * @see UiGrid.rows
   */
  @Prop({ type: [Number, String], required: false })
  public rows?: UiGrid['rows']

  /**
   * Determines if animated grid should have overlay
   */
  @Prop({ type: Boolean, required: false, default: true })
  public withOverlay?: boolean

  @Prop({ type: String, required: true })
  public readonly htmlId!: string
}

export default AnimatedGrid
