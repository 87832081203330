// Copyright © 2022 Move Closer

import { SwiperOptions } from 'swiper'

/**
 * Time interval (in ms) between the successive updates of the loading circle.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const LOADING_CIRCLE_UPDATE_INTERVAL: number = 100

/**
 * The timeframe when each and every single slide is being presented.
 * After this time, Swiper will transition to the next slide.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const SWIPER_AUTOPLAY_DELAY: number = 5000

/**
 * Base (but extendable) configuration of the Swiper.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const SWIPER_OPTIONS: SwiperOptions = {
  autoHeight: true,
  autoplay: {
    delay: SWIPER_AUTOPLAY_DELAY,
    disableOnInteraction: false,
    pauseOnMouseEnter: false
  },
  breakpoints: {
    0: { speed: 500 },
    920: { speed: 1500 }
  },
  pagination: {
    bulletElement: 'li',
    clickable: true,
    el: '.swiper-pagination',
    type: 'bullets'
  }
}
