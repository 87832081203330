















import { BootstrapImage, BootstrapImageProps } from '@movecloser/ui-core'
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { DebouncedFunc, throttle } from 'lodash'
import { UseNonce } from '../../../../../../extensions'

/**
 * @author Olga Milczek <olga.mliczek@movecloser.pl> (original)
 */
@Component<AnimatedImage>({
  name: 'AnimatedImage',
  components: { BootstrapImage },
  created () {
    this.firstImage = this.image

    this.timeout = (this.animationDuration ?? 2) * 1000
  },
  beforeMount () {
    this.updateImage = throttle((newImage: BootstrapImageProps) => {
      this._updateImage(newImage)
    }, this.timeout)
  },
  beforeDestroy () {
    if (typeof this.updateImage.cancel === 'function') {
      this.updateImage.cancel()
    }
  }
})
export class AnimatedImage extends Mixins<UseNonce>(UseNonce) {
  /**
   * Time duration for animation to complete one cycle -may not be set
   * In seconds
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/animation-duration
   */
  @Prop({ type: Number, required: false })
  public animationDuration?: number | null

  /**
   * Given image to render
   */
  @Prop({ type: Object, required: true })
  public image!: BootstrapImageProps

  @Prop({ type: String, required: true })
  public readonly htmlId!: string

  /**
   * Number of current image which faded in
   */
  public currentImage: 1 | 2 = 1

  public isAnimating: boolean = false

  /**
   * First image to render
   */
  public firstImage: BootstrapImageProps | null = null

  /**
   * Second image to render
   */
  public secondImage: BootstrapImageProps | null = null

  /**
   * Updates the image - according to current image.
   *
   * @throttled
   */
  public updateImage!: DebouncedFunc<(newImage: BootstrapImageProps) => void>

  /**
   * Delay of animation in milliseconds
   */
  private timeout: number = 0

  /**
   * CSS classes of first image - determines if image should fade in, fad out or not
   */
  public get firstImageAnimationClass (): string {
    if (!this.isAnimating) {
      return ''
    }
    return this.currentImage === 1 ? 'fade-out' : 'fade-in'
  }

  /**
   * CSS classes of second image - determines if image should fade in, fad out or not
   */
  public get secondImageAnimationClass (): string {
    if (!this.isAnimating) {
      return ''
    }
    return this.currentImage === 1 ? 'fade-in' : 'fade-out'
  }

  /**
   * Determines CSS styles for image
   */
  public get style (): string | undefined {
    if (typeof this.animationDuration !== 'undefined' && this.animationDuration !== null) {
      return `
        #${this.htmlId} {
          animationDuration: ${this.animationDuration}s
        }
      `
    }
  }

  /**
   * Update number of current image
   */
  private _updateImage (newImage: BootstrapImageProps): void {
    if (this.isAnimating) {
      setTimeout(() => {
        this._updateImage(newImage)
      }, 100)
      return
    }

    if (this.currentImage === 1) {
      this.secondImage = newImage
    } else {
      this.firstImage = newImage
    }
    this.isAnimating = true

    /* This is some kind on async JS magic - because the function in `setTimeout`
     should always be triggered before next throttled `_updateImage` i reduce timeout time here.
     */
    setTimeout(this.updateCurrentImage, this.timeout - 10)
  }

  /**
   * Method to watch if image change and if it change set oldImage for animation.
   */
  @Watch('image', { deep: true })
  private onImageChange (newImage: BootstrapImageProps): void {
    this.updateImage(newImage)
  }

  /**
   * Update number of current image
   */
  private updateCurrentImage (): void {
    if (this.currentImage === 1 && this.secondImage !== null) {
      this.firstImage = null
      this.currentImage = 2
    } else if (this.currentImage === 2 && this.firstImage !== null) {
      this.secondImage = null
      this.currentImage = 1
    }

    this.isAnimating = false
  }
}

export default AnimatedImage
