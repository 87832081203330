






import { BootstrapImageProps } from '@movecloser/ui-core'
import { Component, Inject, Prop, Vue } from 'vue-property-decorator'

import { log } from '../../../../../../support'

import { ContainerWidth } from '../../../../../atoms'

import {
  HeroModuleAnimationGridSetup,
  HeroModuleBasicVersionContent,
  HeroModuleVersion
} from '../../../Hero.contracts'

import { changeImage } from '../../partials/AnimatedGrid'

/**
 * Animated version of the `HeroModuleUi`.
 *
 * @see HeroModule
 * @see HeroModuleVersion
 *
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component<Animated>({
  name: 'Animated',
  components: {
    SlideContent: () => import(
      /* webpackChunkName: "frame" */
      '../../partials/SlideContent/SlideContent.vue'
    )
  },
  mounted () {
    this.intervalId = setInterval(() => {
      this.changeImage()
    }, 2000) as unknown as number
  },

  beforeDestroy (): void {
    if (this.intervalId !== null) {
      clearInterval(this.intervalId)
    }
  }
})
export class Animated extends Vue {
  /**
   * @see HeroModuleAnimationContent.addons
   */
  @Prop({ type: Array, required: true })
  public readonly addons!: HeroModuleBasicVersionContent['addons']

  /**
   * @see HeroModuleBasicVersionContent.containerWidth
   */
  @Prop({ type: String, required: false, default: ContainerWidth.Normal })
  public readonly containerWidth!: HeroModuleBasicVersionContent['containerWidth']

  /**
   * @see HeroModuleContent.hasBottomSpacing
   */
  @Prop({ type: Boolean, required: false, default: false })
  public readonly hasBottomSpacing!: Required<HeroModuleBasicVersionContent>['hasBottomSpacing']

  /**
   * @see HeroModuleAnimationContent.gallery
   */
  @Prop({ type: Array, required: true })
  public readonly galleryImages!: Array<BootstrapImageProps>

  /**
   * @see HeroModuleAnimationContent.gallery
   */
  @Prop({ type: Object, required: false })
  public readonly gallerySetup?: HeroModuleAnimationGridSetup

  /**
   * @see HeroModuleBaseContent.showBreadcrumbs
   */
  @Prop({ type: Boolean, required: false, default: false })
  public readonly showBreadcrumbs!: boolean

  @Prop({ type: String, required: true })
  public readonly htmlId!: string

  public readonly version = HeroModuleVersion

  /**
   * Id of interval which change images in animation.
   * Used to clear interval before component is destroyed
   */
  private intervalId: null | number = null

  /**
   * Index of prev image changed during animation
   */
  private previous: number = 0

  @Inject('fetchGalleryImage')
  public fetchGalleryImage!: () => BootstrapImageProps

  @Inject('updateSecondary')
  public updateSecondary!: (image: BootstrapImageProps) => void

  /**
   * Change random image in galleryImage list
   */
  public async changeImage (): Promise<void> {
    try {
      const image: BootstrapImageProps = this.fetchGalleryImage()
      const { index, deleted } = changeImage(this.galleryImages, image, this.previous)

      this.previous = index

      this.$nextTick(() => {
        this.updateSecondary(deleted)
      })
    } catch (e) {
      log(e, 'error')
    }
  }
}

export default Animated
