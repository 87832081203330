// Copyright © 2022 Move Closer

import { BootstrapImageProps } from '@movecloser/ui-core'

import { ChangeImagePayload } from '../../../Hero.contracts'

/**
 * Method which returns index of random image to change.
 * Prevent form changing image with the same index as previous oe and change image for the same image.
 *
 * Warning!: method mutate existing list with Array method `splice`
 *
 * @param imageList - list of image where image will be replaced
 * @param newImage - new image
 * @param prev - id of previously changed image
 *
 * @return randomIndex
 */
const getNextRandomImgId = (imageList: Array<BootstrapImageProps>, newImage: BootstrapImageProps, prev?: number): number => {
  const randomIndex = Math.floor(Math.random() * imageList.length)

  if (typeof prev !== 'undefined') {
    if (prev === randomIndex) {
      return getNextRandomImgId(imageList, newImage, prev)
    }
  }

  if (newImage.src === imageList[randomIndex].src) {
    return getNextRandomImgId(imageList, newImage, prev)
  }

  return randomIndex
}

/**
 * Method to change random image in list
 *
 * Warning!: method mutate existing list with Array method `splice`
 *
 * @param imageList - list of image where image will be replaced
 * @param newImage - new image
 * @param prev - id of previously changed image
 *
 * @return index of changed element
 */
export const changeImage = (imageList: Array<BootstrapImageProps>, newImage: BootstrapImageProps, prev?: number): ChangeImagePayload => {
  if (!Array.isArray(imageList)) {
    throw new Error(`changeImage(): ImageList is not proper Array object! Image list: ${imageList}.`)
  }

  if (imageList.length < 1) {
    throw new Error(`changeImage(): ImageList too short! Image list: ${imageList}.`)
  }

  if (typeof newImage !== 'object' || newImage === null) {
    throw new Error(`changeImage(): NewImage is not proper image. New image: ${JSON.stringify(newImage)}`)
  }

  const randomIndex = getNextRandomImgId(imageList, newImage, prev)

  const deleted = imageList.splice(randomIndex, 1, newImage)

  return { index: randomIndex, deleted: deleted[0] }
}
