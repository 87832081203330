






import { BootstrapImageProps } from '@movecloser/ui-core'
import { Component, Prop, Vue } from 'vue-property-decorator'

import { ContainerWidth } from '../../../../../atoms'

import { HeroModuleBasicVersionContent, HeroModuleVersion } from '../../../Hero.contracts'

/**
 * Basic version of the `HeroModuleUi`.
 *
 * @see HeroModule
 * @see HeroModuleVersion
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<Basic>({
  name: 'Basic',
  components: {
    SlideContent: () => import(
      /* webpackChunkName: "frame" */
      '../../partials/SlideContent/SlideContent.vue'
    )
  }
})
export class Basic extends Vue {
  /**
   * @see HeroModuleBasicVersionContent.addons
   */
  @Prop({ type: Array, required: true })
  public readonly addons!: HeroModuleBasicVersionContent['addons']

  /**
   * @see HeroModuleBasicVersionContent.backgroundImage
   */
  @Prop({ type: Object, required: true })
  public readonly backgroundImage!: BootstrapImageProps

  /**
   * @see HeroModuleBasicVersionContent.containerWidth
   */
  @Prop({ type: String, required: false, default: ContainerWidth.Normal })
  public readonly containerWidth!: HeroModuleBasicVersionContent['containerWidth']

  /**
   * @see HeroModuleContent.hasBottomSpacing
   */
  @Prop({ type: Boolean, required: false, default: false })
  public readonly hasBottomSpacing!: Required<HeroModuleBasicVersionContent>['hasBottomSpacing']

  @Prop({ type: String, required: true })
  public readonly htmlId!: string

  public readonly version = HeroModuleVersion
}

export default Basic
